// extracted by mini-css-extract-plugin
export var wrapper = "customSolution-module--wrapper--28xqb";
export var containerMain = "customSolution-module--containerMain--lN2w3";
export var sectionProblem = "customSolution-module--sectionProblem--3RIhK";
export var sectionSolution = "customSolution-module--sectionSolution--3K4Jc";
export var headingMain = "customSolution-module--headingMain--2Bcyq";
export var problemTitle = "customSolution-module--problemTitle--2Pq0S";
export var problemDesc = "customSolution-module--problemDesc--2KTep";
export var solutionDesc = "customSolution-module--solutionDesc--LGaxo";
export var itemContainer = "customSolution-module--itemContainer--3GkAG";
export var containerRight = "customSolution-module--containerRight--2LjIz";