import React from 'react';
import PropTypes from 'prop-types';
import * as featuresStyles from './features.module.css';
import { GatsbyImage } from 'gatsby-plugin-image';

import FeaturesListItem from './FeaturesListItem';

export default function AppFeatures(props) {
  const { features, featureImage } = props;

  if (features) {
    const totalLength = features && features.length;
    const firstHalfLimit = features && Math.ceil(totalLength / 2);

    var featuresFirstHalf = [];
    var featuresSecondHalf = [];

    for (let i = 0; i < firstHalfLimit; i++) {
      featuresFirstHalf.push(
        <FeaturesListItem
          key={i}
          icon={features[i].icon}
          title={features[i].title}
          desc={features[i].desc}
        />
      );
    }

    for (let i = firstHalfLimit; i < totalLength; i++) {
      featuresSecondHalf.push(
        <FeaturesListItem
          key={i}
          icon={features[i].icon}
          title={features[i].title}
          desc={features[i].desc}
        />
      );
    }
  }
  return (
    <div className={featuresStyles.featuresWrapper}>
      <div className={featuresStyles.mainContainer}>
        <div className={featuresStyles.descContainer}>
          <div className={featuresStyles.titleContent}>Main App Features</div>
          <div className={featuresStyles.descContent}>
            Great designer-developers are a rare breed – part artist, part
            coder, part consultant. That’s me! My background in user experience
            and development enables me to create pixel-perfect experiences.
          </div>
        </div>
        <div className={featuresStyles.featuresContainer}>
          <div className={featuresStyles.featuresList}>{featuresFirstHalf}</div>
          <GatsbyImage
            image={featureImage.childImageSharp.gatsbyImageData}
            style={{
              marginTop: '50px',
              marginBottom: '50px',
              width: '260px',
              marginLeft: '75px',
              marginRight: '75px'
            }}
            alt=""
          />
          <div
            className={`${featuresStyles.featuresList} ${featuresStyles.listRight}`}
          >
            {featuresSecondHalf}
          </div>
        </div>
      </div>
    </div>
  );
}

AppFeatures.propTypes = {
  features: PropTypes.array,
  imageData: PropTypes.object
};
