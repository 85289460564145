import React from 'react';
import PropTypes from 'prop-types';

import * as finaleStyles from './finaleResults.module.css';
import { GatsbyImage } from 'gatsby-plugin-image';

export default function FinaleSection(props) {
  const { description, finalImage } = props;
  return (
    <>
      <section className={finaleStyles.finaleWrapper}>
        <div className={finaleStyles.sectionWhite}>
          <div className={finaleStyles.textContainer}>
            <div className={finaleStyles.title}>Finale Results</div>
            <div className={finaleStyles.desc}>
              {description ? description : ''}
            </div>
          </div>
            <GatsbyImage
              image={finalImage.childImageSharp.gatsbyImageData}
              className={finaleStyles.finalimg}
              imgStyle={{
                height: '500px',
                position: 'initial',
                width: 'initial ',
              }}
              style={{
                position: 'absolute',
                right: 0,
              }}
              alt=""
            />
        </div>
      </section>
    </>
  );
}

FinaleSection.propTypes = {
  description: PropTypes.string,
};
