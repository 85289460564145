import React, { useEffect, useState } from 'react';
import * as allPagesStyles from './allPages.module.css';
import './allPages.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, A11y } from 'swiper';
import { GatsbyImage } from 'gatsby-plugin-image';

//import 'swiper/swiper.scss';
//import 'swiper/components/navigation/navigation.scss';
//import 'swiper/components/pagination/pagination.scss';
//import 'swiper/components/scrollbar/scrollbar.scss';

import './swiper.scss';
import './navigation.scss';
import './pagination.scss';
import './scrollbar.scss';

SwiperCore.use([Navigation, Pagination, A11y]);

export default function AppPagesSection({ allAppPages }) {
  let [numSlides, setNumSlides] = useState(3);

  const checkWindowSize = () => {
    if (window) {
      if (window.innerWidth < 768) setNumSlides(1);
      else setNumSlides(3);
    }
  };

  useEffect(() => {
    checkWindowSize();
    window.addEventListener('resize', checkWindowSize);
    return () => {
      window.removeEventListener('resize', checkWindowSize);
    };
  }, []);

  return (
    <section className={allPagesStyles.wrapper}>
      <div className={allPagesStyles.containerMain}>
        <div className={allPagesStyles.descriptionContainer}>
          <div className={allPagesStyles.title}>All App Pages</div>
          <div className={allPagesStyles.desc}>
            I love creating applications that are engaging and usable and
            believe simplicity combined with a custom innovative approach to
            each project is the key to a successful experience.
          </div>
        </div>
        <div className={allPagesStyles.carouselContainer}>
          <Swiper
            spaceBetween={0}
            slidesPerView={numSlides}
            navigation
            pagination={{ clickable: true }}
          >
            {allAppPages.map((slide, index) => {
              return (
                <SwiperSlide key={index} style={{ textAlign: 'center' }}>
                      <div
                        style={{
                          paddingBottom: 70,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        <GatsbyImage
                          image={slide.imageUrl.childImageSharp.gatsbyImageData}
                          imgStyle={{
                            maxHeight: '550px',
                            width: 'auto',
                            position: 'initial',
                          }}
                          alt={slide.description}
                          className={allPagesStyles.slideImage}
                        />
                        <div
                          style={{ textAlign: 'center', paddingTop: 30 }}
                        ></div>
                      </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </section>
  );
}
