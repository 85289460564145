import React from 'react';
import PropTypes from 'prop-types';
import * as descriptionStyles from './description.module.css';
import { GatsbyImage } from 'gatsby-plugin-image';

export default function DescriptionSection(props) {
  const { description, descriptionImage } = props;

  return (
    <div className={descriptionStyles.wrapper}>
      <div className={descriptionStyles.containerBlack}></div>
      <div className={descriptionStyles.containerWhite}>
        <div className={descriptionStyles.descContainer}>
          <div>
            <div className={descriptionStyles.title}>Project Description</div>
            <div className={descriptionStyles.desc}>
              {description ? description : ''}
            </div>
          </div>
        </div>
        <div className={descriptionStyles.imageContainer}>
          <GatsbyImage
            image={descriptionImage.childImageSharp.gatsbyImageData}
            style={{ height: '602px', width: '312px', margin: 'auto' }}
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

DescriptionSection.propTypes = {
  title: PropTypes.string,
  aboutData: PropTypes.string,
};
