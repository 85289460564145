import React from 'react';
import * as customSolutionStyles from './customSolution.module.css';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

export default function ListItem(props) {
  const { type, title, desc } = props;

  const query = useStaticQuery(graphql`
    query {
      problemIcon: file(relativePath: { eq: "casestudy/problemIcon.svg" }) {
        extension
        publicURL
      }
      solutionIcon: file(relativePath: { eq: "casestudy/solutionIcon.svg" }) {
        extension
        publicURL
      }
    }
  `);

  return (
    <>
      <div className={customSolutionStyles.itemContainer}>
        <div style={{ marginTop: `${type === 'sol' ? '3px' : ''}` }}>
          <img
            src={
              type === 'sol'
                ? query.solutionIcon.publicURL
                : query.problemIcon.publicURL
            }
            alt="Listitem"
          />
        </div>
        <div className={customSolutionStyles.containerRight}>
          <div className={customSolutionStyles.problemTitle}>{title}</div>
          <div
            className={
              type === 'sol'
                ? customSolutionStyles.solutionDesc
                : customSolutionStyles.problemDesc
            }
          >
            {desc}
          </div>
        </div>
      </div>
    </>
  );
}

ListItem.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string,
};
