import React from 'react';
import Container from 'common/src/components/UI/Container';
import PropTypes from 'prop-types';
import * as aboutStyles from './about.module.css';
import { GatsbyImage } from 'gatsby-plugin-image';

export default function AboutSection(props) {
  const { title, aboutData, mobImage } = props;

  return (
    <section className={aboutStyles.wrapper}>
      <Container noGutter mobileGutter width="1200px" height="100%">
        <div className={aboutStyles.containerMain}>
          <div className={aboutStyles.containerLeft}>
            <div>
              <div className={aboutStyles.title}>About {title}</div>
              <div className={aboutStyles.desc}>
                {aboutData ? aboutData : ''}
              </div>
            </div>
          </div>
          <div className={aboutStyles.containerRight}>
            <GatsbyImage
              image={mobImage.childImageSharp.gatsbyImageData}
              style={{
                width: '312px',
                heigth: '602px',
                margin: 'auto',
              }}
              alt=""
            />
          </div>
        </div>
      </Container>
    </section>
  );
}

AboutSection.propTypes = {
  title: PropTypes.string,
  aboutData: PropTypes.string,
};
