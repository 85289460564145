import React from 'react';
import * as customSolutionStyles from './customSolution.module.css';
import PropTypes from 'prop-types';
import ListItem from './ListItem';

export default function CustomSolutionSection(props) {
  const { problems, solutions } = props;
  return (
    <section className={customSolutionStyles.wrapper}>
      <div className={customSolutionStyles.containerMain}>
        <div className={customSolutionStyles.sectionProblem}>
          <div className={customSolutionStyles.headingMain}>
            Initial Problem
          </div>
          {problems
            ? problems.map((problem, index) => {
                return (
                  <ListItem
                    key={index}
                    type="prob"
                    title={problem.title}
                    desc={problem.desc}
                  />
                );
              })
            : ''}
        </div>
        <div className={customSolutionStyles.sectionSolution}>
          <div className={customSolutionStyles.headingMain}>Our Solution</div>
          {solutions
            ? solutions.map((solution, index) => {
                return (
                  <ListItem
                    key={index}
                    type="sol"
                    title={solution.title}
                    desc={solution.desc}
                  />
                );
              })
            : ''}
        </div>
      </div>
    </section>
  );
}

CustomSolutionSection.propTypes = {
  problems: PropTypes.array,
  solution: PropTypes.object,
};
