import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import Seo from '../components/seo';
import Helmet from 'react-helmet';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { portfolioTheme } from 'common/src/theme/portfolio';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from '../containers/Portfolio/portfolio.style';

import Navbar from '../containers/Portfolio/Navbar';
import Footer from '../containers/Portfolio/Footer';
import HeroSection from '../containers/CaseStudy/Hero';
import AboutSection from '../containers/CaseStudy/About';
import DescriptionSection from '../containers/CaseStudy/DescriptionSection';
import AppFeatures from '../containers/CaseStudy/AppFeatures';
import FinaleSection from '../containers/CaseStudy/FinaleResults';
import TestimonialSection from '../containers/CaseStudy/Testimonial';
import TechnologySection from '../containers/CaseStudy/Techonologies';
import CustomSolutonSection from '../containers/CaseStudy/CustomSolution';
import ProjectVideoSection from '../containers/CaseStudy/ProjectVideo';
import AppPagesSection from '../containers/CaseStudy/AppPages';
import ContactSection from '../containers/Portfolio/Contact';

const CaseStudy = (props) => {
  const { caseStudy: data } = props.pageContext;
  const videoLink = props.pageContext.caseStudy.videoLink;
  const clientLink = props.pageContext.caseStudy.link;

  return (
    <ThemeProvider theme={portfolioTheme}>
      <Fragment>
        <Seo
          title={data.shortDescription.substring(0, 60)}
          description={data.description.substring(0, 150)}
          keywords={data.keywords}
        />
        <Helmet>
          <meta
            property="og:image"
            content={data.dynamicCaseStudyHeroImage.childImageSharp.gatsbyImageData.src}
          />
          <meta
            property="og:image:secure"
            content={data.dynamicCaseStudyHeroImage.childImageSharp.gatsbyImageData.src}
          />
          <meta
            property="og:url"
            content={'https://www.almog.io/portfolio/case-study/' + data.slug}
          />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={data.shortDescription} />
          <meta property="og:description" content={data.description} />
        </Helmet>
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <HeroSection
            title={data.title}
            shortDescription={data.shortDescription}
            itunesLink={data.iosLink}
            playStoreLink={data.androidLink}
            heroImage={
              data.dynamicCaseStudyHeroImage && data.dynamicCaseStudyHeroImage
            }
            videoLink={videoLink}
            clientLink={clientLink}
          />
          <AboutSection
            title={data.title}
            mobImage={data.aboutImage}
            aboutData={data.about}
          />
          <DescriptionSection
            descriptionImage={data.descriptionImage}
            description={data.description}
          />
          <AppFeatures
            featureImage={data.FeatureImage}
            features={data.appFeatures}
          />
          <CustomSolutonSection
            problems={data.customSolution && data.customSolution.problems}
            solutions={data.customSolution && data.customSolution.solutions}
          />
          <FinaleSection
            finalImage={data.finalImage}
            description={data.finaleResult}
          />
          <TestimonialSection
            clientName={data.testimonialData.name && data.testimonialData.name}
            designation={
              data.testimonialData.designation &&
              data.testimonialData.designation
            }
            organizationName={
              data.testimonialData.organization &&
              data.testimonialData.organization
            }
            organizationURL={
              data.testimonialData.organizationURL &&
              data.testimonialData.organizationURL
            }
            review={data.testimonialData.review && data.testimonialData.review}
            imageData={data.testimonialData.image && data.testimonialData.image}
          />
          <TechnologySection techImage={data.technologyImage} />
          {data.videoLink ? (
            <ProjectVideoSection videoLink={data.videoLink} />
          ) : (
            ''
          )}

          <AppPagesSection allAppPages={data.allAppPages} />
          <ContactSection />
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
export default CaseStudy;
