// extracted by mini-css-extract-plugin
export var featuresWrapper = "features-module--featuresWrapper--UY-h2";
export var mainContainer = "features-module--mainContainer--1z4xC";
export var titleContent = "features-module--titleContent--3wsei";
export var descContent = "features-module--descContent--3C59M";
export var featuresContainer = "features-module--featuresContainer--3OsL7";
export var subTitle = "features-module--subTitle--8mrI2";
export var featureListItem = "features-module--featureListItem--2-3qv";
export var imageContainer = "features-module--imageContainer--3FPEv";
export var descContainer = "features-module--descContainer--3xvMq";
export var featuresList = "features-module--featuresList--3feDA";
export var listRight = "features-module--listRight--3fCFX";