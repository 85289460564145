import React from 'react';
import * as featuresStyles from './features.module.css';
import PropTypes from 'prop-types';

export default function FeaturesListItem({ icon, title, desc }) {
  return (
    <>
      <div className={featuresStyles.featureListItem}>
        <div>
          <img
            styles={{
              width: '30px ',
              position: 'initial ',
              height: '30px ',
              opacity: 1
            }}
            src={icon.publicURL}
            alt="featuresListItem"
          />
        </div>
        <div className={featuresStyles.subTitle}>{title}</div>
        <div className={featuresStyles.descContent}>{desc}</div>
      </div>
    </>
  );
}

FeaturesListItem.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string,
  desc: PropTypes.string
};
