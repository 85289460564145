import React from 'react';
import * as testimonialStyles from './testimonial.module.css';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';

export default function TestimonialSection(props) {
  const {
    clientName,
    designation,
    organizationName,
    organizationURL,
    review,
    imageData,
  } = props;

  return (
    <section className={testimonialStyles.wrapper}>
      <div
        style={{
          margin: 'auto',
          maxWidth: '870px',
          padding: '150px 20px',
        }}
      >
        <div className={testimonialStyles.title}>What My Client Says</div>
        <div className={testimonialStyles.profileImg}>
          {imageData && (
            <GatsbyImage
              image={imageData.childImageSharp.gatsbyImageData}
              imgStyle={{
                maxWidth: 'min-content',
                borderRadius: '100%',
                position: 'initial',
                objectFit: 'contain',
                width: '100px',
                height: '100px',
              }}
              alt=""
            />
          )}
        </div>
        <div>
          <div
            className={`${testimonialStyles.title} ${testimonialStyles.clientName}`}
          >
            {clientName}
          </div>
          <div className={testimonialStyles.clientAbout}>
            <span className={testimonialStyles.jobtitle}>{designation} </span>
            {organizationURL && (
              <a href={organizationURL} className={testimonialStyles.link}>
                <span>@{organizationName}</span>
              </a>
            )}
          </div>
        </div>
        <div className={testimonialStyles.testimonialText}>{review}</div>
      </div>
    </section>
  );
}

TestimonialSection.propTypes = {
  clientName: PropTypes.string,
  designation: PropTypes.string,
  imageData: PropTypes.object,
  organizationName: PropTypes.string,
  organizationURL: PropTypes.string,
  review: PropTypes.string,
};

TestimonialSection.defaultProps = {
  clientName: '',
  designation: '',
  imageData: null,
  organizationName: '',
  organizationURL: '',
  review: '',
};
