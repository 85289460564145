import React from 'react';
import * as projectVideoStyles from './projectVideo.module.css';

export default function ProjectVideoSection(props) {
  const { videoLink } = props;
  const newLink = videoLink.replace('watch', 'embed');
  return (
    <section>
      <div id="video_section" className={projectVideoStyles.containerBlack}>
        <div className={projectVideoStyles.title}>Project Video</div>
      </div>
      <div className={projectVideoStyles.containerWhite}>
        <iframe
          src={newLink}
          frameBorder="0"
          allowFullScreen
          title="video"
          className={projectVideoStyles.iframeStyle}
        />
      </div>
    </section>
  );
}
