import React from 'react';
import * as technologyStyles from './technologies.module.css';
import { GatsbyImage } from 'gatsby-plugin-image';

export default function TechnologySection({ techImage }) {
  return (
    <>
      <section className={technologyStyles.wrapper}>
        <div className={technologyStyles.containerMain}>
          <div className={technologyStyles.title}>Technology I Used</div>
          <div className={technologyStyles.desc}>
            I tackle development projects of all sorts and believe in choosing
            the right technologies for the task at hand. I’m proficient in a
            wide range of development solutions. Sine 2018, Google Flutter's
            first stable version, I have been focusing on mobile, desktop, smart
            screens, and IoT application development using Google Flutter. I
            consider myself a #FlutterDev.
          </div>
          <div className={technologyStyles.imageContainer}>
            {techImage &&
              techImage.length > 0 &&
              techImage.map((item, index) => {
                return (
                  <GatsbyImage
                    image={item.childImageSharp.gatsbyImageData}
                    key={index}
                    style={{
                      marginRight: '-14px',
                      marginLeft: '-14px',
                      marginBottom: '-14px',
                      width: '200px',
                    }}
                    alt=""
                  />
                );
              })}
          </div>
        </div>
      </section>
    </>
  );
}
