// extracted by mini-css-extract-plugin
export var wrapper = "hero-module--wrapper--1eET_";
export var heroMain = "hero-module--heroMain--Bco3f";
export var title = "hero-module--title--17jYd";
export var heroCenterBackgroundImage = "hero-module--heroCenterBackgroundImage---eoUf";
export var desc = "hero-module--desc--3HWak";
export var linkContainer = "hero-module--linkContainer--1dbpZ";
export var linkButton = "hero-module--linkButton--YYKwi";
export var buttonImg = "hero-module--buttonImg--1yEnR";
export var heroImageContainer = "hero-module--heroImageContainer--2-Qtt";
export var heroImageContainerimg = "hero-module--heroImageContainerimg--39Uu3";
export var circleContainer = "hero-module--circleContainer--aGXDU";
export var bigBlueCircle = "hero-module--bigBlueCircle--22of9";
export var bigPinkCircle = "hero-module--bigPinkCircle--3wVwI";
export var smallPinkCircle = "hero-module--smallPinkCircle--CO-bM";
export var herodynamic = "hero-module--herodynamic--3orl2";
export var smallBlueCircle = "hero-module--smallBlueCircle--L-YE3";
export var adjustPos = "hero-module--adjustPos--2jZR9";
export var blueright = "hero-module--blueright--N_7Mn";
export var leftpink = "hero-module--leftpink--3qc7M";
export var adjustPosPinkCircle = "hero-module--adjustPosPinkCircle--3vEaa";
export var appLinks = "hero-module--appLinks--aG-0b";
export var circleBlue = "hero-module--circleBlue--3U15P";