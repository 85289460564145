import Container from 'common/src/components/UI/Container';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import appleIcon from '../../../../../common/src/assets/images/casestudy/apple.png';
import playIcon from '../../../../../common/src/assets/images/casestudy/playIcon.png';
import youTubeIcon from '../../../../../common/src/assets/images/casestudy/youtube-icon.png';
import websiteIcon from '../../../../../common/src/assets/images/casestudy/website.png';
import YouTubePopup from '../../../../../landing-gatsby/src/components/YouTubePopup';

import * as heroStyles from './hero.module.css';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';

export default function HeroSection(props) {
  const query = useStaticQuery(graphql`
    {
      circlePink: file(relativePath: { eq: "casestudy/circlePink.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
            width: 40
            height: 40
            layout: FIXED
          )
        }
      }
      circleBlue: file(relativePath: { eq: "casestudy/circleBlue.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
            width: 250
            height: 250
            layout: FIXED
          )
        }
      }
      svgBlue: file(relativePath: { eq: "casestudy/BlueBackGround.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
            width: 750
            height: 395
            layout: FIXED
          )
        }
      }
    }
  `);

  const {
    title,
    shortDescription,
    itunesLink,
    playStoreLink,
    heroImage,
    videoLink,
    clientLink,
  } = props;

  const [isVideoOpen, setOpenVideo] = useState(false);

  return (
    <>
      <YouTubePopup
        videoLink={videoLink}
        isVideoOpen={isVideoOpen}
        setOpenVideo={setOpenVideo}
      />
      <div className={heroStyles.wrapper}>
        <Container noGutter mobileGutter width="1200px" height="100%">
          <div className={heroStyles.heroMain}>
            <div>
              <div className={heroStyles.title} id="heroTitle">
                {title}
              </div>
              <div className={heroStyles.desc}>{shortDescription}</div>
              <div
                className={heroStyles.appLinks}
                id="app-links"
                style={{
                  textAlign: 'center',
                  marginTop: 52,
                }}
              >
                {itunesLink ? (
                  <a href={itunesLink} className={heroStyles.linkButton}>
                    <button className={heroStyles.linkButton}>
                      <img
                        src={appleIcon}
                        alt="iTunes Store"
                        className={heroStyles.buttonImg}
                      />{' '}
                      iTunes Store
                    </button>
                  </a>
                ) : (
                  ''
                )}
                {playStoreLink ? (
                  <a href={itunesLink} className={heroStyles.linkButton}>
                    <button className={heroStyles.linkButton}>
                      <img
                        src={playIcon}
                        alt="Google Play"
                        className={heroStyles.buttonImg}
                      />{' '}
                      Google Play
                    </button>
                  </a>
                ) : (
                  ''
                )}
                {videoLink ? (
                  <a
                    href="#video_section"
                    onClick={(e) => setOpenVideo(true)}
                    className={heroStyles.linkButton}
                  >
                    <button className={heroStyles.linkButton}>
                      <img
                        src={youTubeIcon}
                        alt="YouTube"
                        className={heroStyles.buttonImg}
                        style={{
                          width: '14px',
                          height: '16px',
                        }}
                      />{' '}
                      YouTube
                    </button>
                  </a>
                ) : (
                  ''
                )}
                {clientLink ? (
                  <a href={clientLink} className={heroStyles.linkButton}>
                    <button className={heroStyles.linkButton}>
                      <img
                        src={websiteIcon}
                        alt="Website"
                        className={heroStyles.buttonImg}
                        style={{
                          width: '16px',
                          height: '16px',
                        }}
                      />{' '}
                      Website
                    </button>
                  </a>
                ) : (
                  ''
                )}
              </div>
              <div className={heroStyles.circleContainer}>
                <GatsbyImage
                  image={query.circleBlue.childImageSharp.gatsbyImageData}
                  className={heroStyles.adjustPos}
                  style={{
                    overflow: 'initial',
                    position: 'relative',
                    transform: 'translateY(-49%)',
                  }}
                  alt=""
                />
                <GatsbyImage
                  image={query.circlePink.childImageSharp.gatsbyImageData}
                  className={heroStyles.adjustPosPinkCircle}
                  style={{
                    overflow: 'initial',
                    width: '125px',
                    height: '125px',
                    transform: 'translateY(-20%)',
                  }}
                  alt=""
                />
                <GatsbyImage
                  image={query.svgBlue.childImageSharp.gatsbyImageData}
                  className={heroStyles.heroCenterBackgroundImage}
                  alt=""
                />
              </div>
            </div>
            <div className={heroStyles.heroImageContainer}>
              <GatsbyImage
                image={query.circlePink.childImageSharp.gatsbyImageData}
                imgStyle={{
                  minWidth: '40px',
                }}
                className={heroStyles.leftpink}
                style={{
                  overflow: 'initial',
                  position: 'absolute',
                  transform: 'translate(25%,565%)',
                  right: '1162px',
                  top: '453px',
                }}
                alt=""
              />
              <div>
                <GatsbyImage
                  image={heroImage.childImageSharp.gatsbyImageData}
                  className={heroStyles.herodynamic}
                  imgStyle={{
                    height: '300px',
                    position: 'initial',
                    marginBottom: '-5px',
                    objectFit: 'contain',
                    objectPosition: 'bottom',
                  }}
                  alt=""
                />
              </div>

              <GatsbyImage
                image={query.circleBlue.childImageSharp.gatsbyImageData}
                imgStyle={{
                  minWidth: '75px',
                }}
                className={heroStyles.blueright}
                style={{
                  position: 'absolute',
                  overflow: 'initial',
                  width: '75px',
                  height: '75px',
                  left: '1187px',
                  top: '663px',
                }}
                alt=""
              />
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

HeroSection.propTypes = {
  title: PropTypes.string,
  shortDescripton: PropTypes.string,
  itunesLink: PropTypes.string,
  playStoreLink: PropTypes.string,
  heroImage: PropTypes.object,
};
